<template>
  <div>
    <van-nav-bar
      title="选择地块"
      left-text="返回"
      left-arrow
      @click-left="Bank"
    />
    <van-cell title="选择单个日期" :value="date" @click="show = true" />
    <van-calendar
      v-model="show"
      @confirm="onConfirm"
      :min-date="minDate"
      :max-date="maxDate"
    />
    <van-checkbox-group v-model="result">
      <van-cell-group>
        <div
          class="FarmlandList"
          v-for="(item, index) in farmlandList"
          :key="index"
          @click="toggle(index, item.id)"
        >
          <div class="FarmlandList-box">
            <div class="FarmlandList-box-left">
              <div class="FarmlandList-box-left-img">
                <img
                  :src="item.imageUrl"
                  alt=""
                  style="width:100%;height:100%"
                />
              </div>
              <div class="FarmlandList-box-left-word">
                <div class="FarmlandList-box-left-title">
                  {{ item.name }}
                </div>
                <div class="location">
                  {{ item.province_name }}-{{ item.city_name }}-{{
                    item.county_name
                  }}
                </div>
              </div>
            </div>
            <div class="FarmlandList-box-right">
              <div class="FarmlandList-box-right-word">
                <div class="farmland-type">
                  {{ item.crop_show }}
                </div>
                <div class="farmland-cycle"></div>
              </div>
              <div class="FarmlandList-box-right-area">{{ item.area }} 亩</div>
            </div>
            <div class="checkboxe">
              <van-checkbox :name="item" ref="checkboxes" />
            </div>
          </div>
        </div>
      </van-cell-group>
    </van-checkbox-group>
    <div style="width:90%; margin: 10px auto">
      <van-button round block type="info" native-type="submit" style="background:#001f90" @click="seve()"
        >下一步</van-button
      >
    </div>
    <van-dialog
      confirm-button-text="去画地块"
      cancel-button-text="再看看"
      v-model="Dialogshow"
      title="请先画地块，种植作物后再进行此项操作"
      show-cancel-button
      @confirm="handleconfirm"
      @cancel="handlecancel"
    >
    </van-dialog>
  </div>
</template>

<script>
import {
  NavBar,
  Calendar,
  Cell,
  Checkbox,
  CheckboxGroup,
  CellGroup,
  Button,
  Dialog,
  Notify
} from "vant";
import { getFarmlandList } from "@/service/FarmlandList";
// import { getCorpByLandIds } from "@/service/farmworkType";
export default {
  name: "farmworktypeDetail",
  data() {
    return {
      date: "",
      currentCropLifeName: "",
      landId: [],
      show: false,
      Dialogshow: false,
      farmlandList: [],
      result: [],
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2030, 0, 31),
      srcList: {
        1: require("../../assets/image/crop/crop_mihoutao.png"),
        2: require("../../assets/image/crop/crop_yancao.png")
      }
    };
  },
  components: {
    VanNavBar: NavBar,
    VanCalendar: Calendar,
    VanCell: Cell,
    VanCheckboxGroup: CheckboxGroup,
    VanCheckbox: Checkbox,
    VanCellGroup: CellGroup,
    VanButton: Button,
    [Dialog.Component.name]: Dialog.Component,
    [Notify.Component.name]: Notify.Component
  },
  mounted() {
    const date = new Date();
    this.date = this.timeFormat(date);
    this.getfarmlandData();
  },
  methods: {
    getfarmlandData() {
      if (this.$store.state.show === true) {
        this.Dialogshow = true;
      }
      getFarmlandList().then(res => {
        const data = res.data;
        this.farmlandList = data;
      });
    },
    Bank() {
      this.$router.go(-1);
    },
    timeFormat(time) {
      // 时间格式化 2019-09-08
      const year = time.getFullYear();
      const month =
        time.getMonth() + 1 > 10
          ? time.getMonth() + 1
          : "0" + (time.getMonth() + 1);
      const day = time.getDate() > 10 ? time.getDate() : "0" + time.getDate();
      return year + "-" + month + "-" + day;
    },
    onConfirm(date) {
      this.show = false;
      this.date = this.timeFormat(date);
    },
    toggle(index, landId) {
      this.$refs.checkboxes[index].toggle();
      if (this.$refs.checkboxes[index].checked === false) {
        this.landId.push(landId);
      } else if (this.$refs.checkboxes[index].checked === true) {
        this.landId.splice(index, 1);
      }
    },
    seve() {
      const workDate = this.date;
      var landIds = [];
      for (var i = 0; i < this.landId.length; i++) {
        if (landIds.indexOf(this.landId[i]) === -1) {
          landIds.push(this.landId[i]);
        }
      }
      this.$store.commit("setLandIds", landIds);
      this.$store.commit("setWorkDate", workDate);
      if (landIds.length > 0) {
        this.$router.push({
          path: "/publiccrops"
        });
      } else {
        Notify({ type: "warning", message: "请选择地块" });
      }
    },
    handleconfirm() {
      this.$router.push({
        path: "/addfarmland"
      });
    },
    handlecancel() {
      this.$router.push({
        path: "/"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.FarmlandList {
  margin-bottom: 10px;
  position: relative;
  .FarmlandList-box {
    display: flex;
    .FarmlandList-box-left {
      width: 87%;
      display: flex;
      .FarmlandList-box-left-img {
        width: 70px;
        height: 60px;
        flex-shrink: 0;
      }
      .FarmlandList-box-left-word {
        margin-top: 5px;
        margin-left: 5px;
        flex-shrink: 0;
        .FarmlandList-box-left-title {
          width: 50.4vw;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .location {
          margin-top: 16px;
          width: 50.4vw;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .FarmlandList-box-right {
      .FarmlandList-box-right-word {
        display: flex;
        .farmland-type {
          position: absolute;
          left: 188px;
          top: 4px;
        }
        .farmland-cycle {
          margin-top: 5px;
          position: absolute;
          right: 50px;
        }
      }
      .FarmlandList-box-right-area {
        position: absolute;
        right: 50px;
        bottom: 6.53333vw;
      }
    }
    .checkboxe {
      position: relative;
      left: 3.66667vw;
      top: 0.66667vw;
    }
  }
}
</style>
